import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid,
  Slider,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DraftsIcon from "@mui/icons-material/Drafts";
import InboxIcon from "@mui/icons-material/Inbox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import DownloadIcon from "@mui/icons-material/Download";

import CircularProgress from "@mui/material/CircularProgress";

import { useAppContext } from "../AppContext";

import request from "../utils/request";
import { setIn } from "formik";

const Calculator = (props) => {
  const theme = useTheme();
  const { client, item, uploadId, setItems, color, status, pdf, pdfFinal, setColor } = useAppContext();

  const [price, setPrice] = useState(0);
  const [expanded, setExpanded] = useState("material");
  const [materials, setMaterials] = useState([]);
  const [colors, setColors] = useState([]);
  const [quality, setQuality] = useState('');
  const [qualities, setQualities] = useState([]);
  const [processing, setProcessing] = useState([]);
  const [processingList, setProcessingList] = useState([]);
  const [infill, setInfill] = useState(20);
  const [itemInfill, setItemInfill] = useState(20);
  const [quantity, setQuantity] = useState(1);

  const [enableProcessing, setEnableProcessing] = useState(false);
  const [enableQuality, setEnableQuality] = useState(false);

  const [infillMinimum, setInfillMinimum] = useState(20);
  const [infillMaximum, setInfillMaximum] = useState(100);
  const [infillAllowUser, setInfillAllowUser] = useState(true);
  const [selectedProcess, setSelectedProcess] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedColorMaterial, setSelectedColorMaterial] = useState(null);

  const updateRfqPart = async (values) => {
    if (!uploadId) return;
    if (!item._id) return;
    if(item._id[0] === "-") return;

    let data = {
      id: item._id,
      requestId: uploadId,
      volume: item.volume,
      scale: item.scale,
      process: selectedProcess,
      materialCategory: selectedMaterial,
      material: selectedColorMaterial,
      color: color,
      quantity: quantity,
      infill: itemInfill,
      quality: quality,
      processing: processing,
      index: item.index,
    };

    data = { ...data, ...values };

    setItems((oldItems) => {
      let temp = [...oldItems];
      let i = temp.find((element) => element._id === data.id);
      if (!i) return temp;
      i.loading = 1;
      return temp;
    });
   
    const json = await request("/rfqPart/edit/" + item._id, data, {});
    if (json.r == 1) {
      setItems((oldItems) => {
        let temp = [...oldItems];
        let i = temp.find((element) => element._id === data.id);
 
        if (!i) return temp;
        i.price = json.price;
        i.processing = data.processing;
        i.quality = data.quality;
        i.infill = data.infill;
        i.quantity = data.quantity;
        i.color = data.color;
        i.material = data.material;
        i.materialCategory = data.materialCategory;
        i.process = data.process;
        
        i.loading--;
        if (i.loading < 0) i.loading = 0;
        return temp;
      });
    } else {
      setItems((oldItems) => {
        let temp = [...oldItems];
        let i = temp.find((element) => element._id === data.id);
        if (!i) return temp;
        i.loading--;
        if (i.loading < 0) i.loading = 0;
        return temp;
      });
    }
  };

  const changeProcess = (event) => {
    if (!item) return;
    let foundProcess = client.materialConfigs.find(
      (proc) => proc._id === event.target.value
    );
    if (!foundProcess) return;

    setSelectedProcess(foundProcess._id);

    let foundMatCat = foundProcess.materialCategories.find(m => m._id === selectedMaterial);
    if(!foundMatCat) {
      foundMatCat = foundProcess.materialCategories[0];
    }
    setSelectedMaterial(foundMatCat._id);
    setColors(foundMatCat.colors);
    
    let foundColor = foundMatCat.colors.find(c => c._id === color);
    let foundMat;
    if(!foundColor) {
      foundMat = foundMatCat.colors[0].mats.find(m => m._id === selectedColorMaterial);
      foundColor = foundMatCat.colors[0];
      if(!foundMat) {
        foundMat = foundColor.mats[0];
      }
    }
    else{
      foundMat = foundColor.mats.find(m => m._id === selectedColorMaterial);
      if(!foundMat) {
        foundMat = foundColor.mats[0];
      }
    }

    setColor(foundColor._id);
    setSelectedColorMaterial(foundMat._id);

    setMaterials(foundProcess.materialCategories);
    setQualities(foundProcess.qualities);
    setProcessingList(foundProcess.processingList);
    setEnableQuality(foundProcess.quality);
    setEnableProcessing(foundProcess.processing);
    setInfillMinimum(foundProcess.infillMinimum);
    setInfillMaximum(foundProcess.infillMaximum);
    setInfillAllowUser(foundProcess.infillAllowUser);

    let infill = item.infill;
    if (foundProcess.infillMinimum > item.infill) {
      infill = foundProcess.infillMinimum;
      setInfill(infill);
    } else if (foundProcess.infillMaximum < item.infill) {
      infill = foundProcess.infillMaximum;
      setInfill(infill);
    }

    updateRfqPart({ process: foundProcess._id, materialCategory: foundMatCat._id, color: foundColor._id, material: foundMat._id, infill: infill });
  };

  const changeMaterial = (event) => {
    if (!item) return;
    let foundMatCat = materials.find(
      (matCat) => matCat._id === event.target.value
    );
    if (!foundMatCat) return;

    setSelectedMaterial(foundMatCat._id);
    setColors(foundMatCat.colors);

    let foundColor = foundMatCat.colors.find(c => c._id === color);
    let foundMat;
    if(!foundColor) {
      foundMat = foundMatCat.colors[0].mats.find(m => m._id === selectedColorMaterial);
      foundColor = foundMatCat.colors[0];
      if(!foundMat) {
        foundMat = foundColor.mats[0];
      }
    }
    else{
      foundMat = foundColor.mats.find(m => m._id === selectedColorMaterial);
      if(!foundMat) {
        foundMat = foundColor.mats[0];
      }
    }

    setColor(foundColor._id);
    setSelectedColorMaterial(foundMat._id);

    updateRfqPart({ materialCategory: foundMatCat._id, material: foundMat._id, color: foundColor._id });
  };

  const selColor = (event, c) => {
    if (!item || disabled || color === c._id) return;
    setSelectedColorMaterial(c.mats[0]._id);
    setColor(c._id);

    updateRfqPart({ color: c._id });
  };

  const infillCommitted = () => {
    if (!item || infill === item.infill) return;

    setItemInfill(infill);

    updateRfqPart({ infill: infill });
  };

  const changeInfill = (event) => {
    setInfill(event.target.value);
  };

  const changeQuality = (event) => {
    if (!item) return;

    setQuality(event.target.value);

    updateRfqPart({ quality: event.target.value });
  };

  const changeProcessing = (event) => {
    if (!item || disabled) return;

    setProcessing(event.target.value);

    updateRfqPart({ processing: event.target.value });
  };

  const changeQuantity = (event) => {
    if (!item || disabled) return;

    let newQuantity = event.target.value;

    if (isNaN(newQuantity)) {
      return;
    }

    setQuantity(newQuantity);

    if (newQuantity.length == 0) {
      return;
    }

    updateRfqPart({ quantity: newQuantity });
  };



  useEffect(() => {
    if (client !== null && client.processes.length > 0) {
      const proc = client.processes[0];
      const matCat = proc.materialCategories[0];
      setMaterials(proc.materialCategories);
      setInfillMinimum(proc.infillMinimum);
      setInfillMaximum(proc.infillMaximum);
      setInfill(proc.infillMinimum);
      setItemInfill(proc.infillMinimum);
      setInfillAllowUser(proc.infillAllowUser);
      setColors(matCat.colors);

      setSelectedColorMaterial(matCat.colors[0].mats[0]._id);
      setColor(matCat.colors[0]._id);

      setQualities(proc.qualities);
      setProcessingList(proc.processingList);
      setEnableQuality(proc.quality);
      setEnableProcessing(proc.processing);

      setSelectedProcess(proc._id);
      setSelectedMaterial(matCat._id);
    }
  }, [client]);

  useEffect(() => {
    
    if (!item || !client) return;
    let foundProcess = client.processes.find(
      (proc) => proc._id === item.process
    );
    if (!foundProcess) return;
    let foundMatCat = foundProcess.materialCategories.find(
      (matCat) => matCat._id === item.materialCategory
    );
    setMaterials(foundProcess.materialCategories);
    setQualities(foundProcess.qualities);
    setProcessingList(foundProcess.processingList);
    setEnableQuality(foundProcess.quality);
    setEnableProcessing(foundProcess.processing);
    
    if (!foundMatCat) {
    } else {
      setColors(foundMatCat.colors);
    }

    setSelectedProcess(item.process);
    setSelectedMaterial(item.materialCategory);
    setInfill(item.infill);
    setItemInfill(item.infill);
    setInfillAllowUser(foundProcess.infillAllowUser);
    setPrice(item.price);
    setQuantity(item.quantity);
    setColor(item.color);

    if (!item.quality) {
        setQuality([]);
    } else {
      setQuality(item.quality);
    }

    if (item.processing && Array.isArray(item.processing) === false) {
      item.processing = [];
    } else {
      setProcessing(item.processing);
    }
  

  }, [item, setColor, client]);

  let disabled = useMemo(() => {
    if (!item) return true;
    if(status > 0) return true;
    if(item.loading > 0) return true;
    if(item.status > 2 && item.message && item.message.length > 0) return true;
  }, [item, status]);

  const colorList = useMemo(() => {
    return colors.map((col, index) => {
      let cn = "";
      let sx = {
        height: "28px",
        aspectRatio: "1",
        border: "1px solid " + (theme.palette.mode === "dark" ? "#aaa" : "#000"),
        bgcolor: col.hex,
        display: "inline-block",
        borderRadius: "50px",
        opacity: 0.25,
      };

      if (col._id === color) {
        sx.opacity = 1;
        if (disabled) sx.opacity = 0.5;
      }

      if (client.colorInfo) {
        return (
          <Tooltip
            title={
              <>
                <Typography variant="body1" color="inherit">
                  {col.name}
                </Typography>
                {(col.transparency && col.transparency) > 0 && (
                  <Typography variant="body2" color="inherit">
                    Transparency: {col.transparency}%
                  </Typography>
                )}
              </>
            }
            placement="top"
            arrow
            key={index}
          >
            <Box
              className={cn}
              onClick={(e) => selColor(e, col)}
              item
              sx={sx}
              info={col.name}
            ></Box>
          </Tooltip>
        );
      } else {
        return (
          <Box
            className={cn}
            onClick={(e) => selColor(e, col)}
            item
            sx={sx}
            info={col.name}
            key={index}
          ></Box>
        );
      }
    })
  }, [item, colors, color, disabled]);

  useEffect(() => {
    if (!item || !client) return;
    
    //updateRfqPart();
  }, [
    selectedProcess,
    selectedMaterial,
    color,
    quantity,
    itemInfill,
    quality,
    processing,
  ]);

  if (item === null || props.client === null) {
    return "";
  }

  return (
    <Card
      id="calculator-menu"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <CardHeader
        sx={{ pb: 1 }}
        title={ client.configurationBoxLabel || "Configuration" }
        titleTypographyProps={{ variant: "subtitle1" }}
      ></CardHeader>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          maxWidth: 360,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ my: 1 }}>
          <FormControl variant="filled" fullWidth sx={{ height: "30px" }}>
            <InputLabel shrink id="process-label">
              Process
            </InputLabel>
          </FormControl>
          <Box sx={{ mx: 2 }}>
            {client.materialConfigs.length <= 1 && (
              client.materialConfigs.find((proc) => proc._id === selectedProcess)?.name
            )}
            {client.materialConfigs.length > 1 && (
              <Select
                disabled={disabled}
                variant="standard"
                labelId="process-label"
                id="process"
                sx={{ width: "100%" }}
                value={selectedProcess}
                onChange={changeProcess}
              >
                {client.materialConfigs.map((process) => {
                  return (
                    <MenuItem sx={{ p: 0 }} value={process._id} key={process._id}>
                      {client.processInfo && (
                        <Tooltip
                          title={
                            <>
                              <Typography variant="body1" color="inherit">
                                {process.name}
                              </Typography>
                              <Typography variant="body2" color="inherit">
                                {process.description}
                              </Typography>
                            </>
                          }
                          placement="left"
                          arrow
                        >
                          <Box
                            className="calc-process-name"
                            sx={{ width: "100%" }}
                          >
                            {process.name}
                          </Box>
                        </Tooltip>
                      )}
                      {!client.processInfo && (
                        <Box className="calc-process-name" sx={{ width: "100%" }}>
                          {process.name}
                        </Box>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Box>
        </Box>
        <Box sx={{ my: 1 }}>
          <FormControl variant="filled" fullWidth sx={{ height: "30px" }}>
            <InputLabel shrink id="material-label">
              Material
            </InputLabel>
          </FormControl>
          <Box sx={{ mx: 2 }}>
            <Select
              disabled={disabled}
              variant="standard"
              labelId="material-label"
              id="material"
              sx={{ width: "100%" }}
              value={selectedMaterial}
              onChange={changeMaterial}
            >
              {materials.map((matCat) => {
                return (
                  <MenuItem sx={{ p: 0 }} value={matCat._id} key={matCat._id}>
                    {client.materialInfo && (
                      <Tooltip
                        title={
                          <>
                            <Typography variant="body1" color="inherit">
                              {matCat.name}
                            </Typography>
                            <Typography variant="body2" color="inherit">
                              {matCat.description}
                            </Typography>
                          </>
                        }
                        placement="left"
                        arrow
                      >
                        <Box
                          className="calc-materialCategory-name"
                          sx={{ width: "100%" }}
                        >
                          {matCat.name}
                        </Box>
                      </Tooltip>
                    )}
                    {!client.materialInfo && (
                      <Box
                        className="calc-materialCategory-name"
                        sx={{ width: "100%" }}
                      >
                        {matCat.name}
                      </Box>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          <FormControl variant="filled" fullWidth sx={{ height: "30px" }}>
            <InputLabel shrink id="color-label">
              Color
            </InputLabel>
            <TextField
              sx={{ display: "none" }}
              id="color"
              label="Color"
              variant="filled"
            />
          </FormControl>
          <Box sx={{ mx: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
            {colorList}
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          <FormControl variant="filled" fullWidth>
            <InputLabel shrink id="quantity-label">
              Quantity
            </InputLabel>
          </FormControl>
          <Box sx={{ mx: 2 }}>
            <TextField
              disabled={disabled}
              id="quantity"
              variant="standard"
              size="small"
              fullWidth
              value={quantity}
              onChange={changeQuantity}
              sx={{ p: 0, pt: 1 }}
              min={1}
              steps={1}
              className="Mui-disabled"
            />
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          <FormControl variant="filled" fullWidth>
            <InputLabel shrink id="color-label">
              Infill ({infill}%)
            </InputLabel>
          </FormControl>
          <Box sx={{ mx: 2 }}>
            <Slider
              disabled={!infillAllowUser || disabled}
              min={infillMinimum}
              max={infillMaximum}
              steps={1}
              size="small"
              value={infill}
              onChange={changeInfill}
              onChangeCommitted={infillCommitted}
            />
          </Box>
        </Box>
        {enableQuality && (
          <Box sx={{ mb: 1 }}>
            <FormControl variant="filled" fullWidth>
              <InputLabel shrink id="quality-label">
                Quality
              </InputLabel>
            </FormControl>
            <Box sx={{ mx: 2 }}>
              <Select
                disabled={disabled}
                sx={{ bgcolor: "transparent", width: "100%" }}
                variant="standard"
                labelId="quality-label"
                id="quality"
                value={quality}
                onChange={changeQuality}
              >
                {qualities.map((qual, index) => {
                  let discount = parseInt(qual.discount);
                  if (qual.method === "substract") {
                    discount *= -1;
                  }
                  return (
                    <MenuItem key={index} value={qual.name}>
                      {qual.height + "µm"} {qual.name}{" "}
                      {"(" + (100 + discount) + " %)"}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        )}
        {enableProcessing && (
          <Box sx={{ mb: 1 }}>
            <FormControl variant="filled" fullWidth sx={{ height: "30px" }}>
              <InputLabel shrink id="quality-label">
                Further Processing
              </InputLabel>
            </FormControl>
            <Box sx={{ mx: 2 }}>
              <Select
                multiple
                disabled={disabled}
                sx={{ bgcolor: "transparent", width: "100%" }}
                variant="standard"
                labelId="processing-label"
                id="processing"
                value={processing}
                onChange={changeProcessing}
              >
                {processingList.map((proc, index) => {
                  return (
                    <MenuItem disabled={disabled} key={index} value={proc.name}>
                      {proc.name + " ("}
                      {(proc.method === "add" && "+") + proc.discount}{" "}
                      {proc.typ === "absolute" && "$)"}{" "}
                      {proc.typ === "percent" && "%)"}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        )}
      </Box>
      <Divider />
      <CardActions sx={{ height: "36px" }}>
        {status == 0 && (
          <Button
            disabled={disabled}
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<ConnectWithoutContactIcon />}
            onClick={() => props.openContactModal(true)}
          >
            { client.contactButtonLabel || "Get Quote" }
          </Button>
        )}
        {status > 0 && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            target="_blank"
            href={
              process.env.REACT_APP_S3_PATH +
              client.quoterKey +
              "/requests/" +
              uploadId +
              "/" +
              encodeURIComponent(pdfFinal || pdf)
            }
          >
            { client.downloadButtonLabel || "Download Quotation" }
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Calculator;
